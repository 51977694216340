<template>
    <div>
        <h3 class="mb-4 text-dark">Tuition Fee Setup</h3>

        <form
            @submit.prevent="submitHandler"
            id="tuition-setup-form-component"
            class="text-start guardian-loging-form-container mx-auto"
        >
            <user-details
                title="Applicant details"
                :first-name="guardian.FirstName"
                :last-name="guardian.LastName"
                :application-curriculum-block="true"
                :app-curriculum="'Amended Senior Certificate (ASC)'"
                :bundle="bundle"
                :start-date="startDate"
                :exam-sitting-date="examSittingDate"
                :first-billing-date="firstBilledDate"
            ></user-details>

            <payment-methods
                v-if="card"
                :is-active="loading"
                class="fw-bold text-dark"
                :card="card"
            ></payment-methods>

            <payment-methods
                v-else
                :is-active="loading"
                class="fw-bold text-dark"
            ></payment-methods>

            <div class="mb-3">
                <h4 class="mb-3 text-dark">Payment Summary</h4>

                <div
                    class="payment-card-container p-3 rounded mb-3 shadow-sm d-flex justify-content-between"
                >
                    <div class="form-check">
                        <input
                            class="form-check-input"
                            type="radio"
                            name="paymentSummaryChecks"
                            :value="itemPrice.id"
                            id="amPayment"
                            aria-describedby="amPaymentText"
                            :disabled="false"
                            v-model="selectedItemPriceId"
                        >

                        <label class="form-check-label" for="amPayment">
                            <span class="fw-bold text-dark">Monthly tuition fees - {{ this.itemPrice.name }}</span>
                        </label>
                    </div>

                    <p class="mb-0">
                        <span class="fw-bold">{{ getCurrency() }}{{ getDisplayPrice() }}</span>
                    </p>
                </div>
            </div>

            <p><span class="fw-bold">Disclaimer:</span> The prices represented here are an indication of the tuition fees for the current year only. Prices are subject to annual increases.</p>

            <h5 class="mb-3 text-dark">Tuition Setup</h5>

            <p>Tuition of <span class="fw-bold">{{ getCurrencyCode() }} {{ getDisplayPrice() }}</span> will be billed on the 1st of every month for that month's tuition. Therefore your first payment will be on <span class="fw-bold">{{ firstBilledDate }}</span> and continue monthly thereafter.</p>

            <div class="form-floating ps-1 mb-3">
                <div class="form-check">
                    <input
                        id="termsAndConditions"
                        type="checkbox"
                        name="termsAndConditions"
                        required
                        class="form-check-input"
                        v-model="termsAndConditions"
                    >

                    <label for="termsAndConditions" class="consent-label fs-14 form-check-label">By proceeding, you agree to our <a href="https://www.uctonlinehighschool.com/policies" target="_blank">Terms and Conditions</a></label>
                </div>
            </div>

            <div class="form-floating ps-1 mb-3">
                <div class="form-check">
                    <input
                        id="admissionsPolicy"
                        type="checkbox"
                        name="admissionsPolicy"
                        required
                        class="form-check-input"
                        v-model="admissionsPolicy"
                    >

                    <label for="admissionsPolicy" class="consent-label fs-14 form-check-label">By proceeding, you acknowledge that you meet the criteria outlined in our <a href="https://assets.website-files.com/60dc7a9971e17333e8780665/65c1e02929e324b3bb9926e3_Adult%20Matric_Admissions%20Policy_08.01.24.pdf" target="_blank">Admissions Policy.</a></label>
                </div>
            </div>

            <div v-if="hasError" ref="errorAlert" class="alert alert-danger">
                <p v-if="errorMsg" v-html="errorMsg" class="m-0"></p>

                <template v-else>
                    Something went wrong. Please retry or <a href="mailto:techsupport@uctohs.valentureonline.com" target="_blank">contact us for help</a>.
                </template>
            </div>

            <template>
                <p v-if="verifying && !isMobile" class="text-center alert alert-warning">Verifying card, please don't close this page.</p>
                <p v-else-if="!isMobile" class="text-center alert alert-warning">Please click {{ card ? '"Edit Card and Submit Application"' : '"Add Card and Submit Application"' }} and enter your card details. Once this popup is closed we will verify your card and confirm your application. If you experience errors on this page, please refresh the page. If the problem persists, please reach out to <a href="mailto:support@valentureinstitute.com">support@valentureinstitute.com</a>.</p>
            </template>

            <p v-if="redirecting" class="text-center alert alert-warning">We're redirecting you to the next step. <span class="fw-bold">Please don't close the window.</span></p>


            <div class="w-100">
                <a :href="backUrl" :disabled="redirecting || loading || verifying" class="d-block new-btn new-btn-dark new-btn-sm mt-2 mx-2 mx-sm-0">Back</a>

                <!-- Edit Card and Submit Application -->
                <vi-button-spinner
                    v-if="card"
                    data-action="editPaymentMethod"
                    :disabled="loading || redirecting || !card || verifying"
                    :buttonText="'Edit Card and Submit Application'"
                    :loading="(loading || verifying)"
                ><template  v-if="verifying && !pendingVerification" v-slot:loader>Verifying your card details, please wait...</template></vi-button-spinner>

                <!-- Add Card and Submit Application -->
                <vi-button-spinner
                    v-if="!card"
                    data-action="addPaymentMethod"
                    :disabled="loading || redirecting || verifying"
                    :buttonText="'Add Card and Submit Application'"
                    :loading="(loading || verifying)"
                ><template  v-if="verifying" v-slot:loader>Verifying your card details, please wait...</template></vi-button-spinner>

                <!-- Submit Application -->
                <!-- <button v-if="card" type="submit" data-action="submit" :disabled="loading || redirecting || !card" class="new-btn new-btn-dark new-btn-sm mt-2">
                    Submit Application
                </button> -->
                <vi-button-spinner
                    v-if="card"
                    data-action="submit"
                    :disabled="loading || redirecting || !card || verifying"
                    :buttonText="'Submit Application'"
                    :loading="(loading || verifying)"
                >
                    <template  v-if="verifying" v-slot:loader>Verifying your card details, please wait...</template>
                </vi-button-spinner>

                <!-- <p v-if="true || verifying" class="verifying">Verifying your payment details</p> -->
            </div>
        </form>
    </div>
</template>

<script>
import axios from 'axios';
import UserDetails from '../payments/UserDetails.vue';
import PaymentMethods from '../payments/PaymentMethods.vue';
import PaymentSummary from '../payments/PaymentSummary.vue';

export default {
    name: "TuitionStepForm",
    data: () => ({
        loading: false,
        hasError: false,
        errorMsg: null,
        chargeBee: null,
        redirecting: false,
        verifying: false,
        pendingVerification: false,

        termsAndConditions: null,
        admissionsPolicy: null,
        selectedItemPriceId: null,
    }),
    components: {
        UserDetails,
        PaymentMethods,
        PaymentSummary
    },
    props: {
        guardian: {
            type: Object,
            required: true,
        },
        backUrl: {
            type: String,
            required: true,
        },
        itemPrice: {
            type: Object,
            required: true,
        },
        firstBilledDate: {
            type: String,
            required: true,
        },
        chargebeeKey: {
            type: String,
            required: true,
        },
        chargebeeSite: {
            type: String,
            required: true,
        },
        card: {
            type: Object,
            default: null,
        },
        submitUrl: {
            type: String,
            required: true,
        },
        editPaymentMethodUrl: {
            type: String,
            required: true,
        },
        addPaymentMethodUrl: {
            type: String,
            required: true,
        },
        verifyUrl: {
            type: String,
            required: true,
        },
        dashboardUrl: {
            type: String,
            required: true,
        },
        bundle: {
            type: String,
            default: null,
        },
        startDate: {
            type: String,
            default: null,
        },
        examSittingDate: {
            type: String,
            default: null,
        },
    },
    mounted() {
        if (this.itemPrice) {
            this.selectedItemPriceId = this.itemPrice.id;
        }

        this.chargeBee = Chargebee.init({
            site: this.chargebeeSite,
            publishableKey: this.chargebeeKey,
            iframeOnly: true,
        });
    },
    methods: {
        submitHandler(event) {
            let submitButton = event.submitter;
            let action = submitButton.dataset['action'];

            switch (action) {
                case 'submit':
                    this.submitAndEdit(event.target, this.submitUrl);
                    break;
                case 'editPaymentMethod':
                    this.submitAndEdit(event.target, this.editPaymentMethodUrl);
                    break;
                case 'addPaymentMethod':
                    this.submitAndEdit(event.target, this.addPaymentMethodUrl);
                    break;
                default:
                    throw new Error('Unsupported Action');
                    break;
            }
        },
        submitAndEdit(form, url) {
            this.loading = true;
            this.hasError = false;
            this.errorMsg = null;

            let data = {
                termsAndConditions: this.termsAndConditions ? 1 : 0,
                admissionsPolicy: this.admissionsPolicy ? 1 : 0,
            };

            axios.post(url, data)
                .then(res => {
                    let handleUnload = (event) => {
                        var confirmationMessage = 'Are you sure?';

                        if (this.redirecting) {
                            confirmationMessage = 'Please wait while we redirect you to the next step.';
                        } else {
                            return;
                        }

                        (event || window.event).returnValue = confirmationMessage; //Gecko + IE
                        return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
                    };

                    this.hasError = false;
                    this.errorMsg = null;

                    // If not on mobile device where pop up breaks, open popup.
                    if (!this.isMobile && res.data.hasOwnProperty('hostedPage') && res.data.hasOwnProperty('successRedirect')) {
                        window.addEventListener('beforeunload', handleUnload);

                        console.log('opening hosted page')
                        let {
                            hostedPage,
                            successRedirect,
                        } = res.data;

                        this.chargeBee.openCheckout({
                            hostedPage: async () => {
                                return hostedPage;
                            },
                            close: async () => {
                                this.loading = true;
                                this.verifying = true;

                                try {
                                    // Verify that card has been added.
                                    let response = await axios.get(this.verifyUrl);
                                    window.removeEventListener('beforeunload', handleUnload);

                                    if (response.data.hasOwnProperty('isPending') && response.data.isPending) {
                                        this.pendingVerification = response.data.isPending;
                                    } else {
                                        window.location.href = successRedirect;
                                    }

                                } catch (error) {
                                    this.loading = false;
                                    this.hasError = true;
                                    this.verifying = false;
                                    this.errorMsg = error.response.data.error;

                                    this.$nextTick(() => this.scrollIntoView(this.$refs.errorAlert));

                                    return;
                                }
                            },
                        });
                    } else {
                        console.log('redirecting')
                        this.loading = false;
                        this.redirecting = true;

                        window.location.href = res.data.redirect;
                    }
                })
                .catch(err => {
                    this.loading = false;
                    this.hasError = true;
                    this.redirecting = false;

                    if (err.response.data.hasOwnProperty('error')) {
                        this.errorMsg = err.response.data.error;
                    }
                });
        },
        getCurrency() {
            switch (this.itemPrice.currency_code) {
                case 'ZAR':
                    return 'R';
                    break;
                default:
                    throw new Error('Unsupported currency code.');
                    break;
            }
        },
        getCurrencyCode() {
            return this.itemPrice.currency_code;
        },
        getDisplayPrice() {
            return parseFloat(this.itemPrice.price / 100.00).toFixed(2);
        },
        scrollIntoView(node) {
            node.scrollIntoView({
                behavior: 'auto',
                block: 'center',
                inline: 'center',
            });
        },
    },
    computed: {
        isMobile() {
            return window.innerWidth < 480;
        }
    }
}
</script>

<style lang="scss" scoped>

@import '../../../sass/abstract/colours.scss';
@import '../../../sass/utilities/rem-calc.scss';

.consent-label {
    color: $black;
    font-size: rem-calc(14);
}

.payment-card-container {
    background-color: $white;
}

.fs-14 {
    font-size: rem-calc(14);
}

.new-btn {
    max-width: none;
}
</style>
