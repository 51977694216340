<template>
    <form @submit.prevent="submit" method="POST" id="form" class="text-start">
        <input name="learner_on_track" type="hidden" id="learner_on_track">

        <template v-if="hasAvailableDates">
            <div class="application-step-w mx-auto px-3 pb-4 px-lg-0">
                <h4 v-if="shouldRequestGuardianDetails" class="mb-3 text-dark">Guardian Information</h4>

                <div v-if="shouldRequestGuardianDetails" class="row mb-3">
                    <div class="col-12 col-md-6">
                        <div class="form-floating">
                            <input type="text" name="guardian_first_name" id="guardian_first_name" class="form-control new-input" placeholder="First Name" v-model.trim="form.guardian_first_name.value" required>
                            <label for="guardian_first_name" class="form-label">Guardian's First Name</label>

                            <p class="invalid-feedback d-block m-0" v-if="errors && errors['guardian_first_name']" v-html="errors['guardian_first_name'][0]"></p>

                            <span v-else class="text-danger error-message">Please enter a valid first name</span>
                        </div>
                    </div>

                    <div class="col-12 col-md-6">
                        <div class="form-floating">
                            <input type="text" name="guardian_last_name" id="guardian_last_name" class="form-control new-input" placeholder="Last Name" v-model.trim="form.guardian_last_name.value" required>
                            <label for="guardian_last_name" class="form-label">Guardian's Last Name</label>

                            <p class="invalid-feedback d-block m-0" v-if="errors && errors['guardian_last_name']" v-html="errors['guardian_last_name'][0]"></p>

                            <span v-else class="text-danger error-message">Please enter a valid last name</span>
                        </div>
                    </div>
                </div>

                <div v-if="shouldRequestGuardianDetails" class="mb-3">
                    <label for="student_date_of_birth" class="form-label">Guardian's Date of Birth</label>

                    <div class="form-row d-flex">
                        <div class="form-floating me-2">
                            <select class="form-select new-input" name="guardian_year" id="guardian_year" required v-model="guardian.date_of_birth_year">
                                <option :value="null" selected disabled>YYYY</option>
                                <option v-for="year in adultYears" :value="year" :key="`year-${year}`">{{ year }}</option>
                            </select>

                            <label for="guardian_year" >Year</label>
                        </div>

                        <div class="form-floating me-2">
                            <select class="form-select new-input" name="guardian_month" id="guardian_month" required v-model="guardian.date_of_birth_month">
                                <option :value="null" selected disabled>MM</option>
                                <option v-for="(month, key) in yearMonths" :value="key + 1" :key="`month-${month}`">{{ month }}</option>
                            </select>

                            <label for="guardian_month" >Month</label>
                        </div>

                        <div class="form-floating">
                            <select class="form-select new-input" name="guardian_day" id="guardian_day" required v-model="guardian.date_of_birth_day">
                                <option :value="null" selected disabled>DD</option>
                                <option v-for="(day, key) in monthDays" :value="day" :disabled="key + 1 > daysInMonth" :key="`day-${day}`">{{ day }}</option>
                            </select>

                            <label for="guardian_day">Day</label>
                        </div>
                    </div>

                    <p class="invalid-feedback d-block my-1" v-if="errors && errors['guardian_date_of_birth']" v-html="errors['guardian_date_of_birth'][0]"></p>
                </div>

                <div v-if="shouldRequestGuardianDetails" class="row mb-3">
                    <div class="col-12 col-md-4">
                        <div class="form-floating">
                            <select class="form-select new-input" id="dialCodeSelect" aria-label="Floating label select example" required v-model="form.guardian_dialing_code.value">
                                <option :value="null" selected disabled>Please select</option>
                                <option v-for="(option, key) in dialCodes" :value="key" :key="key">{{ option }}</option>
                            </select>
                            <label for="dialCodeSelect">Dialing Code</label>
                        </div>
                    </div>
                    <div class="col-12 col-md-8">
                        <div class="form-floating">
                            <input type="number" class="form-control new-input" id="mobileNumberInput" placeholder="Mobile Number" required v-model="form.guardian_mobile_number.value"/>
                            <label for="mobileNumberInput">Mobile Number</label>
                            <p class="invalid-feedback d-block m-0" v-if="errors && errors['guardian_mobile_number']"
                                v-html="errors['guardian_mobile_number'][0]"></p>
                        </div>
                    </div>
                </div>

                <div v-if="shouldRequestGuardianDetails" class="form-floating">
                    <input type="text" class="form-control new-input" id="howDidYouHearAboutUs" placeholder="How did you hear about UCT Online High School?" v-model="form.guardian_how_did_you_hear.value"/>
                    <label for="howDidYouHearAboutUs" class="long-label-select">How did you hear about UCT Online High School?</label>
                    <p class="invalid-feedback d-block m-0" v-if="errors && errors['guardian_how_did_you_hear']" v-html="errors['guardian_how_did_you_hear'][0]"></p>
                </div>
            </div>

            <div class="application-step-w mx-auto px-3 px-lg-0">
                <div ref="initialScrollTarget" id="initialScrollTarget"></div>
                <p class="mt-2 application-step-w mx-auto">Click <a class="text-light-blue" href="https://www.uctonlinehighschool.com/blog/whats-the-difference-the-amended-senior-certificate-vs-the-national-senior-certificate" target="_blank">here</a> to find out more about these</p>

                <h4 class="mb-3 text-dark">{{ learnersLabel.information }}</h4>

                <vi-alert>Learner's legal name, including middle name, as stated on their official Birth Certificate, Identity Document or Passport.</vi-alert>

                <div class="row mb-3">
                    <div class="col-12 col-md-6">
                        <div class="form-floating">
                            <input type="text" name="student_first_name" id="student_first_name" class="form-control new-input" :placeholder="learnersLabel.firstName" v-model.trim="form.student_first_name.value" required>
                            <label for="student_first_name" class="form-label">{{ learnersLabel.firstName }}</label>

                            <p class="invalid-feedback d-block m-0" v-if="errors && errors['student_first_name']" v-html="errors['student_first_name'][0]"></p>

                            <span v-else class="text-danger error-message">Please enter a valid first name</span>
                        </div>
                    </div>

                    <div class="col-12 col-md-6">
                        <div class="form-floating">
                            <input type="text" name="student_last_name" id="student_last_name" class="form-control new-input" :placeholder="learnersLabel.lastName" v-model.trim="form.student_last_name.value" required>
                            <label for="student_last_name" class="form-label">{{ learnersLabel.lastName }}</label>

                            <p class="invalid-feedback d-block m-0" v-if="errors && errors['student_last_name']" v-html="errors['student_last_name'][0]"></p>

                            <span v-else class="text-danger error-message">Please enter a valid last name</span>
                        </div>
                    </div>
                </div>

                <div class="mb-3">
                    <label for="student_date_of_birth" class="form-label">{{ learnersLabel.dob }}</label>

                    <div class="form-row d-flex">
                        <div class="form-floating me-2">
                            <select class="form-select new-input" :class=" !birthYear ? 'text-muted' : ''" name="year" id="year" v-model.trim="birthYear" required>
                                <option :value="null" selected disabled>YYYY</option>
                                <option v-for="year in adultYears" :value="year" :key="`year-${year}`">{{ year }}</option>
                            </select>
                            <label for="year" >Year</label>
                        </div>

                        <div class="form-floating me-2">
                            <select class="form-select new-input" :class=" !birthMonth ? 'text-muted' : ''" name="month" id="month" v-model.trim="birthMonth" required>
                                <option :value="null" selected disabled>MM</option>
                                <option v-for="(month, key) in yearMonths" :value="key + 1" :key="`month-${month}`">{{ month }}</option>
                            </select>
                            <label for="month" >Month</label>
                        </div>

                        <div class="form-floating">
                            <select class="form-select new-input" :disabled="!birthMonth || !birthYear" :class=" !birthDay ? 'text-muted' : ''" name="day" id="day" v-model.trim="birthDay" required>
                                <option :value="null" selected disabled>DD</option>
                                <option v-for="(day, key) in monthDays" :value="day" :disabled="key + 1 > daysInMonth" :key="`day-${day}`">{{ day }}</option>
                            </select>
                            <label for="day">Day</label>
                        </div>
                    </div>

                    <p class="invalid-feedback d-block my-1" v-if="birthDay > daysInMonth">Please select a valid day for the month selected</p>

                    <p class="invalid-feedback d-block my-1" v-if="errors && errors['student_date_of_birth']" v-html="errors['student_date_of_birth'][0]"></p>
                </div>

                <!-- <div v-if="canAddAdultMatricApplication" class="row mb-1 mt-1 input-sub-heading text-secondary">
                    <p>Can't find age here? If you'd like to apply for adult matric, please <a href="#" @click.prevent="switchToAdultMatric">click here</a> or select our adult matric curriculum above.</p>
                </div>

                <div class="row mb-1 mt-1 input-sub-heading text-secondary">
                    <p><a :href="getCapsLeadFormUrl()" target="_blank">Can't find your age here? Click here to join our Early Interest List to be the first to know when new grades open</a></p>
                </div> -->

                <div v-if="isTooOldForCaps" class="alert alert-info text-center">
                    As per our admissions policy, based on the Date of Birth you have selected, you are currently too old to be admitted to our SA National Senior Certificate (CAPS)
                    programme.
                    <br>
                    You already have an Adult Matric application with us and cannot create another one. Please make sure the Date of Birth you selected is correct or switch to the Dashboard to update your Adult Matric application.
                    <a v-if="!hasAmApplication" href="#" class="new-btn new-btn-dark new-btn-sm d-block switch-button mt-3" @click.prevent="switchToAdultMatric">Switch to Adult Matric</a>
                    <a v-else :href="backUrl" class="new-btn new-btn-dark new-btn-sm d-block switch-button mt-3">Go to Dashboard</a>
                </div>

                <!-- TODO: Link to Adult Matric -->

                <div v-if="shouldRequestGuardianDetails" class="row mb-3">
                    <div class="col-12 col-md-4">
                        <div class="form-floating">
                            <select class="form-select new-input" id="dialCodeSelect" aria-label="Floating label select example" required v-model="form.guardian_dialing_code.value">
                                <option :value="null" selected disabled>Please select</option>
                                <option v-for="(option, key) in dialCodes" :value="key" :key="key">{{ option }}</option>
                            </select>
                            <label for="dialCodeSelect">Dialing Code</label>
                        </div>
                    </div>
                    <div class="col-12 col-md-8">
                        <div class="form-floating">
                            <input type="number" class="form-control new-input" id="mobileNumberInput" placeholder="Mobile Number" required v-model="form.guardian_mobile_number.value"/>
                            <label for="mobileNumberInput">Mobile Number</label>
                            <p class="invalid-feedback d-block m-0" v-if="errors && errors['mobile_number']"
                                v-html="errors['mobile_number'][0]"></p>
                        </div>
                    </div>
                </div>

                <div class="form-floating mb-3">
                    <select class="form-select new-input" name="gender" id="gender" v-model.trim="form.gender.value">
                        <option value="null" selected disabled>Please select</option>
                        <option v-for="(option, key) in applicationModel.gender" :value="key" :key="key">{{ option }}</option>
                    </select>
                    <label for="gender">Gender</label>

                    <p class="invalid-feedback d-block m-0" v-if="errors && errors['gender']" v-html="errors['gender'][0]"></p>
                </div>

                <div class="form-floating mb-3">
                    <select class="form-select new-input" name="primary_language" id="primary_language" v-model.trim="form.primary_language.value">
                        <option value="null" selected disabled>Please select</option>
                        <option v-for="(option) in languages" :value="option.id" :key="option.id">{{ option.name }}</option>
                    </select>
                    <label for="primary_language">Home language</label>

                    <p class="invalid-feedback d-block m-0" v-if="errors && errors['primary_language']" v-html="errors['primary_language'][0]"></p>
                </div>

                <div class="form-floating mb-3">
                    <select class="form-select new-input" name="country_of_residence" id="country_of_residence" v-model.trim="form.country_of_residence.value">
                        <option value="null" selected disabled>Please select</option>
                        <optgroup :label="key" v-for="(countryOption, key) in countries" :key="key">
                            <option v-for="(option, key) in countryOption" :value="key" :key="key">{{ option }}</option>
                        </optgroup>
                    </select>
                    <label for="country_of_residence">Country of residence</label>

                    <p class="invalid-feedback d-block m-0" v-if="errors && errors['country_of_residence']" v-html="errors['country_of_residence'][0]"></p>
                </div>

                <label class="form-label" for="citizenship">Is country of residence the same as country of citizenship?</label>

                <div class="mb-3">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="is_citizenship_same_as_country_of_residence" id="citizenshipYes"  value="1" v-model.trim="form.is_citizenship_same_as_country_of_residence.value">
                        <label class="form-check-label" for="citizenshipYes">
                            Yes
                        </label>
                    </div>

                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="is_citizenship_same_as_country_of_residence"  id="citizenshipNo"    value="0" v-model.trim="form.is_citizenship_same_as_country_of_residence.value">
                        <label class="form-check-label" for="citizenshipNo">
                            No
                        </label>
                    </div>

                    <p class="invalid-feedback d-block m-0" v-if="errors && errors['is_citizenship_same_as_country_of_residence']" v-html="errors['is_citizenship_same_as_country_of_residence'][0]"></p>
                </div>

                <div class="form-floating mb-3" v-if="isCitizenshipDifferent">
                    <select class="form-select new-input" name="country_of_citizenship" id="country_of_citizenship" v-model.trim="form.country_of_citizenship.value">
                        <optgroup :label="key" v-for="(countryOption, key) in countries" :key="key">
                            <option value="null" selected disabled>Please select</option>
                            <option v-for="(option, key) in countryOption" :value="key" :key="key">{{ option }}</option>
                        </optgroup>
                    </select>
                    <label for="country_of_citizenship">Country of citizenship</label>

                    <p class="invalid-feedback d-block m-0" v-if="errors && errors['country_of_citizenship']" v-html="errors['country_of_citizenship'][0]"></p>
                </div>

                <div>
                    <h4 class="mb-3 text-dark">Relationship to learner</h4>

                    <p>Please indicate how you (the guardian) are related to the learner. e.g. I am the learner's mother, father, uncle or legal guardian</p>

                    <div class="form-floating mb-3">
                        <select class="form-select new-input" name="student_guardian_relationship" id="student_guardian_relationship" v-model.trim="form.student_guardian_relationship.value" >
                            <option :value="null">Select...</option>
                            <option v-for="(item) in applicationModel.relationships" :value="item.id" :key="item.id">{{ item.name }}</option>
                        </select>
                        <label for="student_guardian_relationship" class="form-label">Relationship to learner</label>

                        <p class="invalid-feedback d-block m-0" v-if="errors && errors['student_guardian_relationship']" v-html="errors['student_guardian_relationship'][0]"></p>
                    </div>
                </div>

                <div>
                    <h4 class="mb-3 text-dark">Information about prior school report</h4>

                    <p>Indicate which of these are relevant and provide the latest grades received (the most recent term or end-of-year report). You will be required to validate these results by submitting their latest school report at a later stage.</p>

                    <div class="form-floating mb-3">
                        <select class="form-select new-input" name="english_type" id="english_type" v-model.trim="form.english_type.value">
                            <option value="null" selected disabled>Please select</option>
                            <option v-for="(item,key) in applicationModel.englishTypes"  :value="key" :key="key">{{ item }}</option>
                        </select>
                        <label for="english_type">English Type</label>
                        <p class="invalid-feedback d-block m-0" v-if="errors && errors['english_type']" v-html="errors['english_type'][0]"></p>
                    </div>

                    <div class="form-floating mb-3">
                        <select v-if="form.english_type.value == 0" name="latest_grade_english" id="latest_grade_english" class="form-select new-input" v-model.trim="form.latest_grade_english.value">
                            <option value="null" disabled selected>Please select</option>
                            <option v-for="option in englishGradeOptions" :value="option" :key="option">{{ option }}</option>
                        </select>

                        <select v-else-if="form.english_type.value == 1" name="latest_grade_english" id="latest_grade_english" class="form-select new-input" v-model.trim="form.latest_grade_english.value">
                            <option value="null" disabled selected>Please select</option>
                            <option v-for="option in englishGradeOptions" :value="option" :key="option">{{ option }}</option>
                        </select>

                        <label v-if="form.english_type.value !== null" for="latest_grade_english">Latest grade - English (%)</label>

                        <p class="invalid-feedback d-block m-0" v-if="errors && errors['latest_grade_english']" v-html="errors['latest_grade_english'][0]"></p>
                    </div>

                    <div ref="mathTypeInput" class="form-floating mb-3">
                        <select class="form-select new-input" name="maths_type" id="maths_type" v-model.trim="form.maths_type.value">
                            <option value="null" selected disabled>Please select</option>
                            <option v-for="(item,key) in applicationModel.mathsTypes"  :value="key" :key="key">{{ item }}</option>
                        </select>
                        <label for="maths_type">Maths type</label>

                        <p class="invalid-feedback d-block m-0" v-if="errors && errors['maths_type']" v-html="errors['maths_type'][0]"></p>
                    </div>

                    <div v-if="showInvalidMathLitError" class="mb-3 alert alert-danger">
                        You cannot select Math Literacy with your current grade of entry choice, please select Mathematics to continue.
                    </div>

                    <div class="form-floating mb-3">
                        <select v-if="form.maths_type.value !== null" name="latest_grade_maths" id="latest_grade_maths" class="form-select new-input" v-model.trim="form.latest_grade_maths.value">
                            <option value="null" disabled selected>Please select</option>
                            <option v-for="option in mathGradeOptions" :value="option" :key="option">{{ option }}</option>
                        </select>

                        <label v-if="form.maths_type.value !== null" for="latest_grade_maths">Latest grade - Maths (%)</label>

                        <p class="invalid-feedback d-block m-0" v-if="errors && errors['latest_grade_maths']" v-html="errors['latest_grade_maths'][0]"></p>
                    </div>

                    <div class="mb-4">
                        Disclaimer: The information you have provided about your learner's prior school report is verified against the documents you upload during the document verification stage to ensure they meet our admissions criteria.
                    </div>
                </div>

                <h4 class="mb-3 text-dark" v-if="GradeOrYearLabel">{{ GradeOrYearLabel }}</h4>

            </div>

            <div class="application-step-w mx-auto px-3 px-lg-0">
                <div class="mb-3 px-0 container">
                    <div class="row">
                        <div
                            v-for="(grade, key) in grades"
                            :key="key"
                            class="col-6 col-lg-4"
                            :class="{'d-none': !isAllowedGrade(grade)}"
                        >
                            <vi-selectable-card
                                :key="key"
                                :label="grade"
                                :trueValue="key"
                                v-model="form.grade_of_entry.value"
                                :isChecked="form.grade_of_entry.value === key"
                            ></vi-selectable-card>
                        </div>
                    </div>
                </div>
            </div>

            <div class="application-step-w mx-auto px-3 px-lg-0">
                <p class="input-sub-heading mb-2">Click <a class="text-light-blue" :href="capsAgeBracket" target="_blank">here</a> to see our age brackets table</p>

                <p class="invalid-feedback d-block m-0" v-if="errors && errors['grade_of_entry']" v-html="errors['grade_of_entry'][0]"></p>

                <p class="mb-0 mt-2" v-if="closedGradeText">Please note: The following grades are currently closed: {{ closedGradeText }}</p>
            </div>

            <div class="application-step-w mx-auto px-3 px-lg-0">
                <div>
                    <label class="form-label" for="student_started_grade">Has the learner already started this grade?</label>
                    <div class="mb-3">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="student_started_grade" id="studentStartedGradeYes" value="1" v-model.trim="form.student_started_grade.value">
                            <label class="form-check-label" for="studentStartedGradeYes">
                                Yes
                            </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="student_started_grade" id="studentStartedGradeNo" value="0" v-model.trim="form.student_started_grade.value">
                            <label class="form-check-label" for="studentStartedGradeNo">
                                No
                            </label>
                        </div>
                        <p class="invalid-feedback d-block m-0" v-if="errors && errors['student_started_grade']" v-html="errors['student_started_grade'][0]"></p>
                    </div>
                </div>
            </div>

            <div class="application-step-w mx-auto px-3 px-lg-0" v-if="form.grade_of_entry.value == null || !hasBirthDate">
                <vi-alert>Please select the learner's date of birth and grade before selecting your start date.</vi-alert>
            </div>

            <div class="application-step-w mx-auto px-3 px-lg-0" v-if="Object.keys(startDates).length !== 0 && hasBirthDate &&  (form.grade_of_entry.value != null)">
                <h4 class="mb-3 text-dark">Please select your start date</h4>

                <div class="application-step-w mx-auto px-lg-0" v-if="!hasValidStartDatesForEnrollment()">
                    <vi-alert  :type="`vi-error`"  target="_blank">
                        <p>As per our <a href="https://assets.website-files.com/60dc7a9971e17333e8780665/6538ea565ef713390cd25b14_Admissions%20Policy%20for%20CAPS_03.10.2023.pdf" target="_blank">admissions policy</a>, based on the Date of Birth and Grade you have selected, you will not be of eligible age to enter {{ form.grade_of_entry.value }} on the following start dates. We advise that you apply for a grade that you will be eligible for based on our <a :href="capsAgeBracket" target="_blank">age table</a>.</p>

                        <template v-if="isTooOld && !hasAmApplication">
                                <p>Based on your birth date and selected grade, you do not meet the <a href="https://d3up5sxva9rssf.cloudfront.net/portal-data/uct/documents/UCT_Online_High_School_CAPS_Age_Brackets.pdf" target="_blank">age requirements</a> for the National Senior Certificate. We suggest considering Adult Matric instead.</p>
                                <p>If you'd like to apply for adult matric, please <a href="#" @click.prevent="switchToAdultMatric">click here</a> or select our adult matric curriculum above.</p>
                        </template>

                        <template v-if="isTooOld && hasAmApplication">
                            <p>You already have an Adult Matric application in progress. <a :href="backUrl">Click here</a> to navigate to the dashboard to continue your application.</p>
                        </template>

                        <template v-if="isTooYoung">
                            <p>Not finding any start dates? The age you selected looks more appropriate for a lower grade.</p>
                        </template>
                    </vi-alert>
                </div>

                <div class="mb-3 px-0 container">
                    <div class="row">
                        <div
                            v-for="(option, key) in startDates"
                            :key="key"
                            class="col-6 col-lg-4"
                        >
                            <vi-selectable-card
                                :disabled="!validDateForEnrollmentRules(key)"
                                :key="key"
                                :label="option.value"
                                :trueValue="key"
                                v-model="form.start_date.value"
                                :isChecked="form.start_date.value === key"
                                :hasCountdown="true"
                            >
                                <template v-if="option.description" v-slot:content>
                                    <p class="text-center curriculum-description">{{ option.description }}</p>
                                </template>
                            </vi-selectable-card>
                            <countdown-timer v-if="form.start_date.value === key" :countdown-date="option.enabled_to"></countdown-timer>
                        </div>
                    </div>

                    <p class="invalid-feedback d-block m-0" v-if="errors && errors['start_date']" v-html="errors['start_date'][0]"></p>
                </div>
            </div>

            <div v-if="form.grade_of_entry.value != null || hasBirthDate" class="application-step-w mx-auto px-3 px-lg-0">
                <div class="m-0 input-sub-heading text-secondary">
                    <p><a :href="getCapsLeadFormUrl()" target="_blank">Can't find an appropriate start date? Click here to join our Early Interest List to be the first to know when new start dates open</a></p>
                </div>
            </div>

            <div class="application-step-w mx-auto px-3 px-lg-0">
                <p>This is dependent on paying the placement fee and submitting the necessary supporting documents on time.</p>

                <div class="mb-3" id="lateEnrolementBlock" v-if="isLateEnrolement">
                    <p>Please note that you have applied as part of our late enrolment cohort. As a result, your learner will need to participate in an academic catch-up plan to move through content timeously and diligently to catch up with the rest of the school cohort. Your January tuition payment will be processed as soon as you set up your subscription and your next monthly tuition fee will be due at the beginning of February.</p>
                </div>

                <div class="mb-3" v-if="isTermDisclaimer">
                    <div class="my-4">
                        <p class="font-italic">Please note that if your learner does not have a valid report from the previous term, the application will not meet the entrance requirements and will be declined. For Grade 11 applicants, please also provide your final Grade 10 report to verify the information submitted in the application.</p>
                    </div>
                </div>

                <div class="mb-3" id="learnerOnTrackBlock" v-if="isLearnerOnTrack">
                    <label class="form-label" for="learner_on_track">Is the learner on track to complete the previous term before starting with us?</label>

                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="learner_on_track" id="learnerOnTrackYes" value="1" v-model.trim="form.learner_on_track.value" >
                        <label class="form-check-label" for="learnerOnTrackYes">
                            Yes
                        </label>
                    </div>

                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="learner_on_track" id="learnerOnTrackNo" value="0" v-model.trim="form.learner_on_track.value">
                        <label class="form-check-label" for="learnerOnTrackNo">
                            No
                        </label>
                    </div>

                    <p class="invalid-feedback d-block m-0" v-if="errors && errors['learner_on_track']" v-html="errors['learner_on_track'][0]"></p>
                </div>

                <vi-error-flash
                    v-if="unexpectedError"
                    errorMessage="We are unable to save your application right now. Please try again later."
                ></vi-error-flash>

                <div class="d-flex justify-content-center justify-content-sm-between flex-wrap w-100">
                    <a :href="backUrl"  class="new-btn new-btn-dark new-btn-sm mt-2 mx-2 mx-sm-0">Back</a>
                    <vi-button-spinner
                        buttonId="appStepTwoButton"
                        :buttonClass="{
                            'new-btn': true,
                            'new-btn-dark': true,
                            'new-btn-sm': true,
                            'mt-2': true,
                        }"
                        :disabled="nextIsDisabled"
                        :buttonText="nextButtonText"
                        :loading="loading"
                    ></vi-button-spinner>
                </div>
            </div>
        </template>

        <div v-else class="application-step-w mx-auto px-3 px-lg-0">
            <div ref="initialScrollTarget"></div>
            <div class="application-step-3">
                <div class="lead-wrapper">
                    <p class="mb-3">Our {{ curriculumName }} Applications open soon!<br>Be the first to find out when they do:</p>

                    <a
                        class="anchor-btn new-btn new-btn-dark mt-2"
                        @click="redirectToLeadForm"
                    >Capture my details</a>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import moment from "moment";
import helperMixin from "../mixins/helper_mixin";
import scroll_to from "../mixins/scroll_to";
import error_handling from "../mixins/error_handling";
import ViSelectableCard from './ViSelectableCard.vue';
    export default {
    components: { ViSelectableCard },
        name: "GuardianLearnerAppStepOneForm",
        data() {
            return {
                form: {
                    applied_curriculum : {
                        value: this.chosenCurriculum,
                        type : 'common',
                    },
                    student_first_name: {
                        value: null,
                        type : 'common'
                    },
                    student_last_name: {
                        value: null,
                        type : 'common'
                    },
                    student_date_of_birth: {
                        value: null,
                        type : 'common'
                    },
                    primary_language: {
                        value: null,
                        type : 'common'
                    },
                    country_of_residence: {
                        value: null,
                        type : 'common'
                    },
                    country_of_citizenship: {
                        value: null,
                        type : 'common'
                    },
                    is_citizenship_same_as_country_of_residence: {
                        value: null,
                        type : 'common'
                    },
                    student_guardian_relationship: {
                        value: null,
                        type : 'common'
                    },
                    english_type: {
                        value: null,
                        type : 'caps'
                    },
                    latest_grade_english: {
                        value: null,
                        type : 'caps'
                    },
                    maths_type: {
                        value: null,
                        type : 'caps'
                    },
                    latest_grade_maths: {
                        value: null,
                        type : 'caps'
                    },
                    grade_of_entry: {
                        value: null,
                        type : 'common'
                    },
                    student_started_grade: {
                        value: null,
                        type : 'caps'
                    },
                    start_date: {
                        value: null,
                        type : 'common'
                    },
                    learner_on_track: {
                        value: null,
                        type:'caps'
                    },
                    gender : {
                        value : null,
                        type : 'common'
                    },

                    // Guardian Information not captured in sign up.
                    guardian_dialing_code: {
                        value : null,
                        type : 'common'
                    },
                    guardian_mobile_number: {
                        value : null,
                        type : 'common'
                    },
                    guardian_how_did_you_hear: {
                        value : null,
                        type : 'common'
                    },
                    guardian_date_of_birth: {
                        value : null,
                        type : 'common'
                    },
                    guardian_first_name: {
                        value : null,
                        type : 'common'
                    },
                    guardian_last_name: {
                        value : null,
                        type : 'common'
                    },
                },
                lateEnrolementAcknowledged: true,
                GradeOrYearLabel : null,
                isLearnerOnTrack : false,
                errors: null,
                loading: false,
                unexpectedError: false,
                application : null,
                birthYear: null,
                birthMonth: null,
                birthDay: null,
                yearMonths:  ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
                monthDays: [],
                learnersLabel: {},
                previouslySelectedCurriculum: null,
                isDisabled: true,
                hasAvailableDates: true,
                restoreDateOptions: false,
                isTermDisclaimer: false,
                showInvalidMathLitError: false,

                shouldRequestGuardianDetails: false,
                guardian: {
                    date_of_birth_day: null,
                    date_of_birth_month: null,
                    date_of_birth_year: null,
                },
            };
        },
        mixins: [
            helperMixin,
            scroll_to,
            error_handling,
        ],
        props: [
            'userId',
            'languages',
            'backUrl',
            'countries',
            'capsDates',
            'grades',
            'years',
            'applicationModel',
            'applicationId',
            'closedGradeText',
            'closedYearText',
            'activeStep',
            'submitRoute',
            'nextButtonText',
            'getApplicationRoute',
            'preferredCurriculum',
            'userDetails',
            'capsAgeBracket',
            'canAddAdultMatricApplication',
            'leadFormUrls',
            'duplicateRoute',
            'englishGradeOptions',
            'mathGradeOptions',
            'dialCodes',
            'captureGuardianDetails',
            'enrollmentRules',
            'chosenCurriculum',
            'offeredCurriculumOptions',
            'hasAmApplication',
        ],
        created() {
            if (this.getApplicationRoute) {
                // helper mixin function
                this.getApplication(this.getApplicationRoute).then(response => {
                    if (response.data.redirect) {
                        location.href = response.data.redirect;
                    }
                    let application = response.data.application;

                    if (application.get_applied_curriculum.identifier == 'adult-matric') {
                        this.switchToAdultMatric();
                        return;
                    }

                    this.application = application;
                    this.loadForm(this.application);
                })
                .catch(error => {
                    this.unexpectedError = true;
                })
            }
            this.setCurriculumData();
        },
        mounted() {
            if (!this.form.applied_curriculum.value && this.preferredCurriculum) {
                this.form.applied_curriculum.value = this.preferredCurriculum;

                if (this.isApplyingFor('adult-matric')) {
                    this.switchToAdultMatric();
                }
            }

            // No curriculum selected, by default select CAPS.
            if (!this.form.applied_curriculum.value) {
                let caps = this.offeredCurriculumOptions.find(curriculum => curriculum.identifier === 'caps');

                if (caps) {
                    this.form.applied_curriculum.value = caps.id;
                }
            }

            this.monthDays = Array.from({ length : 31 }, (v , k) => k + 1);

            this.shouldRequestGuardianDetails = this.captureGuardianDetails;

            this.initGuardianDetails();
            // scroll down when caps is clicked
            let curriculumCard = document.querySelector('.curriculum-card label');
            let scrollLocation = document.querySelector("#initialScrollTarget");
            curriculumCard.addEventListener('click', event => {
                window.scroll(0, scrollLocation.offsetTop);
            });

            setTimeout(() => {
                this.registerInputListeners();
            }, 50);
        },
        computed: {
            startDates() {
                let currentSelectedGrade = this.form.grade_of_entry.value;

                if (currentSelectedGrade == null) {
                    return [];
                }

                return this.capsDates[currentSelectedGrade];
            },
            isLateEnrolement() {
                let lateEnrolmentDates = [
                    '2025-01-20',
                    '2025-01-27',
                    '2025-02-03',
                    '2025-02-10',
                    '2025-02-17'
                ];

                return lateEnrolmentDates.includes(this.form.start_date.value);
            },
            nextIsDisabled() {
                return !this.hasValidStartDatesForEnrollment() || (this.isLateEnrolement && !this.lateEnrolementAcknowledged);
            },
            daysInMonth() {
                if (this.birthMonth && this.birthYear) {
                    return new Date(this.birthYear, this.birthMonth, 0).getDate();
                }

                return 31;
            },
            getYears() {
                let twentyOneYearsAgo = (new Date()).getFullYear() - 21;
                let years = Array.from({ length : 11 }, (v , k) => k + twentyOneYearsAgo);

                return years;
            },
            adultYears() {
                let pastHundreadyears = (new Date()).getFullYear() - 10;
                let years = Array.from({ length : 100 }, (v , k) => pastHundreadyears - k);

                return years;
            },
            /**
            * Get Years of the basis of qualification
            *
            * @return {object}
            */
            filteredyears() {
                let year =  Object.keys(this.years);

                if (this.form.qualification_choice.value == 0) {
                    year = year.filter( function (el) {
                        return !icseYears.includes(el);
                    });
                }

                if (
                    !this.application  ||
                    (this.application.applied_curriculum != this.form.applied_curriculum.value) ||
                    (this.application.qualification_choice != this.form.qualification_choice.value)
                ) {
                    this.form.grade_of_entry.value = null;
                }

                return Object.keys(this.years)
                    .filter((key) => year.includes(key))
                    .reduce((obj, key) => {
                            return Object.assign(obj, {
                                [key]: this.years[key]
                            });
                    }, {});
            },
            isCitizenshipDifferent(){
                return this.form.is_citizenship_same_as_country_of_residence.value == 0 ? true : false;
            },
            isSouthAfricanCitizenship() {
                if (this.form.country_of_residence.value != null) {
                    if (
                        this.form.country_of_residence.value != 'ZA' &&
                        this.form.is_citizenship_same_as_country_of_residence.value == 1
                    ) {
                        return false;
                    }
                }

                if (this.form.country_of_citizenship.value != null) {
                    if (
                        this.form.country_of_citizenship.value != 'ZA' &&
                        this.isCitizenshipDifferent
                    ) {
                        return false;
                    }
                }

                return true;
            },
            curriculumName() {
                switch (true) {
                    case this.isApplyingFor('caps'):
                        return 'SA National Senior Certificate (CAPS)';
                    default:
                        return '';
                }
            },
            hasBirthDate() {
                return this.birthYear && this.birthMonth && this.birthDay;
            },
            isTooYoung() {
                let isTooYoung = true;
                let startYears = Object.values(this.startDates)
                    .map(date => /\d{4}/.exec(date.value)[0])
                    .filter(year => !!year);

                for (const year of startYears) {
                    isTooYoung = isTooYoung && this.isTooYoungForYear(year);
                }

                return isTooYoung;
            },
            isTooOld() {
                let isTooOld = true;

                let startYears = Object.values(this.startDates)
                    .map(date => /\d{4}/.exec(date.value)[0])
                    .filter(year => !!year);

                for (const year of startYears) {
                    isTooOld = isTooOld && this.isTooOldForYear(year);
                }

                return isTooOld;
            },
            isTooOldForCaps() {
                if (!this.isApplyingFor('caps') || !(this.birthYear && this.birthMonth && this.birthDay)) {
                    return false;
                }

                let rule = this.enrollmentRules['caps']['Grade 12'];

                if (!rule) {
                    return false;
                }

                let dobDate = moment(new Date(this.birthYear, this.birthMonth - 1, this.birthDay));

                dobDate.set({hours: 0, minutes: 0, seconds: 0});

                let startDatesArr = Object.values(this.capsDates).map(startDate => Object.keys(startDate)).flat();
                let startDates = Array.from(new Set(startDatesArr));

                return startDates.reduce((isTooOld, startDate) => {
                    let year = /\d{4}/.exec(startDate)[0];

                    if (!year) {
                        return isTooOld;
                    }

                    let maxAgeDate = new Date(`${year}-${rule.max_age_day}`);
                    maxAgeDate.setHours(0, 0, 0, 0);

                    let ageAtMaxDate = this.calculateAge(maxAgeDate);

                    return isTooOld || ageAtMaxDate > rule.max_age;
                }, false)
            },
            hasSelectedGrade8Or9() {
                return ['Grade 8', 'Grade 9'].includes(this.form.grade_of_entry.value);
            }
        },
        methods: {
            isTooOldForYear(year) {
                if (!(this.birthYear && this.birthMonth && this.birthDay) || !this.form.grade_of_entry.value) {
                    return false;
                }

                let rule = this.getEnrolmentRulesForCurriculum();

                if (!rule) {
                    return false;
                }

                let dobDate = moment(new Date(this.birthYear, this.birthMonth - 1, this.birthDay));
                let maxAgeDate = new Date(`${year}-${rule.max_age_day}`);

                dobDate.set({hours: 0, minutes: 0, seconds: 0});
                maxAgeDate.setHours(0, 0, 0, 0);

                let ageAtMaxDate = this.calculateAge(maxAgeDate);

                return ageAtMaxDate > rule.max_age;
            },
            isTooYoungForYear(year) {
                if (!(this.birthYear && this.birthMonth && this.birthDay) || !this.form.grade_of_entry.value) {
                    return false;
                }

                let rule = this.getEnrolmentRulesForCurriculum();

                if (!rule) {
                    return false;
                }

                let dobDate = moment(new Date(this.birthYear, this.birthMonth - 1, this.birthDay));
                let minAgeDate = new Date(`${year}-${rule.min_age_day}`);

                dobDate.set({hours: 0, minutes: 0, seconds: 0});
                minAgeDate.setHours(0, 0, 0, 0);

                let ageAtMinDate = this.calculateAge(minAgeDate);

                return ageAtMinDate < rule.min_age;
            },
            getEnrolmentRulesForCurriculum() {
                let appCurriculum = 'caps';

                const enrollmentRules = this.enrollmentRules[appCurriculum];

                return enrollmentRules[this.form.grade_of_entry.value];
            },
            initGuardianDetails() {
                // First name
                this.form.guardian_first_name.value = this.userDetails.first_name == 'unknown' ? '' : this.userDetails.first_name;

                // Last name
                this.form.guardian_last_name.value = this.userDetails.last_name == 'unknown' ? null : this.userDetails.last_name;
            },
            /**
            * Submit form
            *
            * @return {void}
            */
            async submit() {
                this.loading = true;
                this.unexpectedError = false;

                if (this.showInvalidMathLitError) {
                    this.scrollIntoView(this.$refs.mathTypeInput);
                    this.loading = false;
                    return;
                }

                if (!this.applicationId) {
                    let action = await this.checkIfDuplicatesExist();

                    if (action === 'redirect') {
                        window.location = this.backUrl;
                        return;
                    }
                }

                let includedTypes = [ 'common', 'caps' ];

                let dob = new Date(this.birthYear, this.birthMonth - 1, this.birthDay);
                this.form.student_date_of_birth.value = dob.toISODate();

                if (this.shouldRequestGuardianDetails) {
                    let guardianDateOfBirth = new Date(
                        this.guardian.date_of_birth_year,
                        this.guardian.date_of_birth_month - 1,
                        this.guardian.date_of_birth_day,
                    );

                    this.form.guardian_date_of_birth.value = guardianDateOfBirth.toISODate();
                }

                // helper mixin function
                let data = this.filterFormDataBasedOnCurriculum(this.form, "type", includedTypes, "value");
                data.active_step = this.activeStep;
                data.user_id = this.userId;

                data.guardian_id = this.userDetails.crm_user_id;

                const urlParams = new URLSearchParams(window.location.search);
                data.scholarship_interest = urlParams.get('lls') === 'true';

                try {
                    let response = await axios.post(this.submitRoute, data);

                    if (response.status === 200) {
                        const relationship = this.applicationModel.relationships
                            .find(relationshipObj => relationshipObj.id === parseInt(this.form.student_guardian_relationship.value));

                        const curriculum = this.applicationModel.offeredCuriculum
                            .find(curriculumObj => curriculumObj.id === parseInt(this.form.applied_curriculum.value));

                        if (relationship != null && curriculum != null) {
                            this.triggerGaEvent(
                                relationship.name,
                                this.form.grade_of_entry.value,
                                curriculum.name
                            );
                        }

                        location.href = response.data.redirect;
                    }
                    this.loading = false;
                } catch (error) {
                    const data = error.response.data;
                    if (error.response.status === 422) {
                        this.errors = data.errors;

                        this.$nextTick(() => {
                            let firstError = this.firstError();

                            if (firstError) {
                                this.scrollIntoView(firstError.parentNode);
                            }
                        });
                    } else {
                        this.unexpectedError = true;
                    }
                    this.loading = false;
                }
            },
            async checkIfDuplicatesExist() {
                let data = {
                    student_first_name: this.form.student_first_name.value,
                    student_last_name: this.form.student_last_name.value,
                };

                let response = await axios.post(this.duplicateRoute, data);

                // Not handling failed responses here. Error should not prevent users
                // from creating an application.
                if (response.status === 200) {
                    let data = response.data;

                    if (data.hasOwnProperty('hasDuplicates') && data.hasDuplicates) {
                        const modalElement = document.querySelector('#duplicateApplications');
                        const modalInstance = new bootstrap.Modal(modalElement, {});

                        const closeButtons = Array.from(document.querySelectorAll('[data-dismiss-modal]'));

                        modalInstance.show();

                        return new Promise(resolve => {
                            let shouldResolve = true;

                            modalElement.addEventListener('hide.bs.modal', () => {
                                if (shouldResolve) {
                                    resolve('continue');
                                }
                            });

                            closeButtons.forEach(button => {
                                button.onclick = (event) => {
                                    shouldResolve = false;
                                    modalInstance.hide();
                                    resolve(event.target.dataset['dismissModal']);
                                };
                            });
                        });
                    }
                }

                return Promise.resolve('continue');
            },
            /**
            * show the learner on track field if month is greater than march
            *
            * @return {void}
            */
            checkTermDropdownBasedonStartDate() {
                const startDate = this.form.start_date.value;
                if (! startDate) {
                    // Reset since no start date is selected.
                    this.isLearnerOnTrack = false;
                    this.isTermDisclaimer = false;
                    return false;
                }

                const startDateDate = new Date(startDate);
                const startDateMonth = startDateDate.getMonth();

                let termValue = 1;
                if (startDateMonth >= 3) {
                    termValue = 2;
                }

                if (termValue === 2) {
                    this.isLearnerOnTrack = true;
                    this.isTermDisclaimer = true;
                } else {
                    this.isLearnerOnTrack = false;
                    this.isTermDisclaimer = false;
                    this.form.learner_on_track.value = null;
                }
            },
            triggerGaEvent(relationship, gradeApplyingFor, curriculumApplyingFor) {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'learnerInformation',
                    'learnerRelationship': relationship,
                    'gradeApplying': gradeApplyingFor,
                    'curriculum': curriculumApplyingFor,
                    'userid': window.valenture.userId,
                });
            },
            handleChange: function() {
                if (this.value = null) {
                    this.isDisabled = true;
                } else {
                    this.isDisabled = false;
                }
            },
            // isApplyingFor('adult-matric') - application for adult-matric
            // isApplyingFor('caie') - application for caie
            // isApplyingFor('caps') - application for caps
            isApplyingFor(target) {
                let targetCurriculum = this.applicationModel.offeredCuriculum.find(curriculum => target === curriculum.identifier);

                if (targetCurriculum != null) {
                    return this.form.applied_curriculum.value == targetCurriculum.id;
                }

                return false;
            },

            redirectToLeadForm() {
                let leadFormUrl = this.getCapsLeadFormUrl();

                if (leadFormUrl != null) {
                    window.open(leadFormUrl);
                }
            },

            getCapsLeadFormUrl() {
                let decodedUrls = JSON.parse(this.leadFormUrls);

                return decodedUrls.caps;
            },

            isAllowedGrade(grade) {
                return this.capsDates.hasOwnProperty(grade);
            },

            /**
            * set start date based on curriculum
            *
            * @return {void}
            */
            setStartDate(dates) {
                this.form.start_date.value = null;
                if (
                    this.application &&
                    Object.keys(dates).includes(this.application.start_date)
                ) {
                   this.form.start_date.value = this.application.start_date;
                }
            },
            /**
            * set grade of entry based on based on curriculum
            *
            * @return {void}
            */
            setGradeOfEntry(GradeOrYear) {
                this.form.grade_of_entry.value = null;
                if (
                    this.application &&
                    this.application.grade_of_entry &&
                    this.application.grade_of_entry.includes(GradeOrYear)
                ) {
                   this.form.grade_of_entry.value = this.application.grade_of_entry;
                }
            },
            setBirthDate() {
                let dob = null;
                if (this.isApplyingFor('adult-matric')) {
                    dob = this.userDetails.date_of_birth;
                } else {
                    if (this.application) {
                        dob = this.application.student_date_of_birth;
                        if (this.application.applied_curriculum == 3) {
                           [this.birthDay, this.birthMonth, this.birthYear] = [null, null, null];
                            dob = null;
                        }
                    } else {
                        if (this.previouslySelectedCurriculum == 3) {
                            [this.birthDay, this.birthMonth, this.birthYear] = [null, null, null];
                            dob = null;
                        }
                    }
                }

                if (dob) {
                    // prepopulate date of birth when editing application
                    [this.birthYear, this.birthMonth, this.birthDay] = dob.split('-');

                    this.birthDay = parseInt(this.birthDay);
                    this.birthMonth = parseInt(this.birthMonth);
                    this.birthYear = parseInt(this.birthYear);
                    this.birthYear = this.getYears.includes(this.birthYear) ? this.birthYear : null;
                }
            },
            setNameAndLabel() {
                if (this.isApplyingFor('adult-matric')) {
                    this.form.student_first_name.value = this.userDetails.first_name == 'unknown' ? null : this.userDetails.first_name;
                    this.form.student_last_name.value = this.userDetails.last_name == 'unknown' ? null : this.userDetails.last_name;
                    this.learnersLabel  = {
                        information: "Information",
                        firstName: "First name",
                        lastName: "Last name",
                        dob: "Date of birth"
                    }
                } else {
                    if (this.application) {
                        this.form.student_first_name.value = this.application.student_first_name;
                        this.form.student_last_name.value = this.application.student_last_name;

                        if (this.application.applied_curriculum == 3) {
                            this.form.student_first_name.value = null;
                            this.form.student_last_name.value = null;
                        }
                    } else {
                        if (this.previouslySelectedCurriculum == 3) {
                            this.form.student_first_name.value = null;
                            this.form.student_last_name.value = null;
                        }
                    }

                    this.learnersLabel  =  {
                        information: "Learner Information",
                        firstName : "Learner's first name(s)",
                        lastName : "Learner's last name",
                        dob: "Learner's date of birth"
                    }
                }
            },
            setCurriculumData() {
                this.setBirthDate();
                this.setNameAndLabel();

                this.GradeOrYearLabel = "Please select your grade of entry";
                this.setGradeOfEntry('Grade');
                this.setStartDate(this.capsDates);
            },
            isAllowedForGrade12(startDate) {
                let currentCurriculum = this.form.applied_curriculum.value;
                let currentSelectedGrade = this.form.grade_of_entry.value;

                if (currentSelectedGrade === null) {
                    return true;
                }

                if (currentCurriculum === 1 && startDate === '2023-07-10' && currentSelectedGrade === 'Grade 12') {
                    return false;
                }

                return true;
            },
            calculateAge(onDate) {
                let dobDate = new Date(this.birthYear, this.birthMonth - 1, this.birthDay);
                let onDateDate = new Date(onDate);
                let age = onDateDate.getFullYear() - dobDate.getFullYear();
                let m = onDateDate.getMonth() - dobDate.getMonth();
                if (m < 0 || (m === 0 && onDateDate.getDate() < dobDate.getDate())) {
                    age--;
                }
                return age;
            },
            validDateForEnrollmentRules(startDate) {

                if (!(this.birthYear && this.birthMonth && this.birthDay) || !this.form.grade_of_entry.value) {
                    return false;
                }

                let appCurriculum = 'caps';

                if (!appCurriculum) {
                    return false;
                }

                const enrollmentRules = this.enrollmentRules[appCurriculum];

                let rule = enrollmentRules[this.form.grade_of_entry.value];
                if (!rule) {
                    return false;
                }

                let dobDate = moment(new Date(this.birthYear, this.birthMonth - 1, this.birthDay));
                let year = (new Date(startDate)).getFullYear();
                let minAgeDate = new Date(`${year}-${rule.min_age_day}`);
                let maxAgeDate = new Date(`${year}-${rule.max_age_day}`);

                dobDate.set({hours: 0, minutes: 0, seconds: 0});
                minAgeDate.setHours(0, 0, 0, 0);
                maxAgeDate.setHours(0, 0, 0, 0);

                // Minimum birthdate
                let maxBirthDate = moment(new Date(`${year - rule.min_age}-${rule.min_age_day} 00:00:00`));
                let minBirthDate = moment(new Date(`${year - rule.max_age - 1}-${rule.max_age_day} 00:00:00`)); // -1 to account for the year between 16 - 17, technically still 16

                if ((dobDate.unix() === maxBirthDate.unix())) {
                    return false;
                }

                if (this.isApplyingFor('caps') && (dobDate.unix() === minBirthDate.unix())) {
                    return true;
                }

                let ageAtMinDate = this.calculateAge(minAgeDate);
                let ageAtMaxDate = this.calculateAge(maxAgeDate);

                return ageAtMinDate >= rule.min_age && ageAtMaxDate <= rule.max_age;
            },
            hasValidStartDatesForEnrollment() {
                let hasValidDates = false;

                if (this.startDates.length == 0) {
                    return false;
                }

                for (let date in this.startDates) {
                    if (this.validDateForEnrollmentRules(date)) {
                        hasValidDates = true;
                    }
                }

                return hasValidDates;
            },
            switchToAdultMatric() {
                let adultMatric = this.offeredCurriculumOptions.find(curriculum => curriculum.identifier === 'adult-matric');

                this.$emit('curriculum-select', { chosenCurriculum: adultMatric.id });
            },
            registerInputListeners() {
                let inputs = Array.from(document.querySelectorAll('input[name="student_last_name"], input[name="student_first_name"], input[name="guardian_first_name"], input[name="guardian_last_name"]'));
                let firstError = () => {
                    return Array.from(document.querySelectorAll('.error-message'))
                        .filter(node => {
                            if (node.hasOwnProperty('checkVisibility')) {
                                return node.checkVisibility({ opacityProperty: true, visibilityProperty: true });
                            }

                            return true;
                        })[0];
                };

                inputs.forEach(input => {
                    input.addEventListener('invalid', event => {
                        input.classList.add('invalid');

                        setTimeout(() => {
                            this.scrollIntoView(firstError());
                        }, 15);
                    }, false);

                    input.addEventListener('input', event => {
                        input.classList.remove('invalid');
                    }, false);

                    input.addEventListener('blur', event => {
                        input.checkValidity();
                    })
                });
            }
        },
        watch: {
            'form.start_date.value': function (val) {
                this.checkTermDropdownBasedonStartDate();
            },
            'form.applied_curriculum.value': function (val, oldVal) {
                this.form.start_date.value = null;
                this.previouslySelectedCurriculum = oldVal
                this.setCurriculumData();

                switch (true) {
                    case this.isApplyingFor('caps'):
                        this.hasAvailableDates = this.capsDates.length !== 0;
                        break;
                    default:
                        this.hasAvailableDates = true;
                        break;
                }

                this.scrollIntoView(this.$refs.initialScrollTarget);
            },
            'form.grade_of_entry.value': function (val) {
                this.form.start_date.value = null;

                let selectedMathTypeIndex = this.form.maths_type.value;
                let hasSelectedMathLit = selectedMathTypeIndex && this.applicationModel.mathsTypes[selectedMathTypeIndex] === "Maths Literacy";

                this.showInvalidMathLitError = false;

                if (this.hasSelectedGrade8Or9 && hasSelectedMathLit) {
                    this.showInvalidMathLitError = true;
                    this.form.maths_type.value = null;
                    this.form.latest_grade_maths.value = null;
                }
            },
            'form.maths_type.value': function (val) {
                if (val === null) {
                    return;
                }

                let selectedMathTypeIndex = this.form.maths_type.value;
                let hasSelectedMathLit = selectedMathTypeIndex && this.applicationModel.mathsTypes[selectedMathTypeIndex] === "Maths Literacy";

                if (this.hasSelectedGrade8Or9 && hasSelectedMathLit) {
                    this.form.grade_of_entry.value = null;
                } else {
                    this.showInvalidMathLitError = false;
                }
            },
            'form.latest_grade_maths.value': function (val) {
                if (val === null) {
                    return;
                }

                let selectedMathTypeIndex = this.form.maths_type.value;
                let hasSelectedMathLit = selectedMathTypeIndex && this.applicationModel.mathsTypes[selectedMathTypeIndex] === "Maths Literacy";
                let hasSelectedGrade8Or9 = ['Grade 8', 'Grade 9'].includes(this.form.grade_of_entry.value);

                if (hasSelectedGrade8Or9 && hasSelectedMathLit) {
                    this.form.grade_of_entry.value = null;
                }
            },
            'birthYear': function (val) {
                this.form.start_date.value = null;
            },
            'birthMonth': function (val) {
                this.form.start_date.value = null;
            },
            'birthDay': function (val) {
                this.form.start_date.value = null;
            },
            'showInvalidMathLitError': function (val) {
                if (val) {
                    this.scrollIntoView(this.$refs.mathTypeInput);
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
    option:empty {
        display: none;
    }

    .lead-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 2rem;

        p {
            font-size: 1.5rem;
            font-weight: 600;
            text-align: center;
        }
    }

    .anchor-btn {
        padding-left: 16px;
        padding-right: 16px;
        width: 50% !important;
    }

    .curriculum-description {
        font-size: 0.8rem;
    }

    .switch-button {
        max-width: none;
    }

    .error-message {
        font-size: 0.8em;
    }

    input ~ .error-message {
        display: none;
    }

    input.invalid ~ .error-message {
        display: block;
    }
</style>
