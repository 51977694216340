<template>
    <form @submit.prevent="submit" class="adult-matric-learner-info">
        <div class="container">

            <vi-alert :type="`vi-error`" v-if="startDateExpired">
                Your selected start date is no longer available. Please choose a new one.
            </vi-alert>

            <div class="row">
                <div class="col-12">
                    <div class="information text-start">
                        <h4 class="mb-3 text-dark">Understanding the Adult Matric Offering</h4>

                        <p>The Adult Matric offering prepares adult learners to complete their Amended Senior Certificate (ASC), either by completing a single subject, or the full ASC Preparation Programme.</p>

                        <ul>
                            <li>UCT Online High School provides you with the learning content, technical support and your own Support Advisor.</li>
                            <li>We recommend using a laptop or desktop to access our online campus. Some subjects require additional stationery, review the list <a target="_blank" href="https://www.uctonlinehighschool.com/policies">here</a>.</li>
                            <li>Check your eligibility to write the ASC examinations by reviewing the requirements <a target="_blank" href="https://www.education.gov.za/Curriculum/SeniorCertificate/SCRegistration.aspx">here</a>. This link will also guide you in registering to write your exams with the Department of Education.</li>
                        </ul>

                        <h4 class="mb-3 text-start text-dark">Applicant Information</h4>

                        <p>Applicant legal name as stated on official Birth Certificate, Identity Document or Passport.</p>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-md-6">
                    <div class="form-floating input-first-name">
                        <input
                            type="text"
                            name="firstName"
                            id="firstName"
                            class="form-control new-input"
                            placeholder="First Name"
                            v-model.trim="firstName"
                            :readonly="!captureGuardianDetails"
                            @keydown="clearError('firstName')"
                        >

                        <label for="firstName" class="form-label">First name</label>
                    </div>

                    <template v-if="hasError('firstName')">
                        <error-message
                            v-for="message in getErrors('firstName')"
                            :key="message"
                            :message="message"
                            class="text-start"
                        ></error-message>
                    </template>
                </div>

                <div class="col-12 col-md-6">
                    <div class="form-floating input-last-name">
                        <input
                            type="text"
                            name="lastName"
                            id="lastName"
                            class="form-control new-input"
                            placeholder="Last Name"
                            v-model.trim="lastName"
                            :readonly="!captureGuardianDetails"
                            @keydown="clearError('lastName')"
                        >
                        <label for="lastName" class="form-label">Last Name</label>
                    </div>

                    <template v-if="hasError('lastName')">
                        <error-message
                            v-for="message in getErrors('lastName')"
                            :key="message"
                            :message="message"
                            class="text-start"
                        ></error-message>
                    </template>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="input-date-of-birth">
                        <label class="form-label d-block text-start">Date of Birth</label>

                        <div class="form-row d-flex">
                            <div class="form-floating me-2">
                                <select
                                    class="form-select new-input"
                                    name="birthYear"
                                    id="birthYear"
                                    v-model.trim="birthYear"
                                    @change="clearError('dateOfBirth')"
                                >
                                    <option :value="null" selected disabled>YYYY</option>
                                    <option v-for="year in getBirthYears()" :value="year" :key="`year-${year}`">{{ year }}</option>
                                </select>

                                <label for="birthYear" >Year</label>
                            </div>

                            <div class="form-floating me-2">
                                <select
                                    class="form-select new-input"
                                    name="birthMonth"
                                    id="birthMonth"
                                    v-model.trim="birthMonth"
                                    :disabled="!birthYear"
                                    @change="clearError('dateOfBirth')"
                                >
                                    <option :value="null" selected disabled>MM</option>
                                    <option v-for="(month, key) in months" :value="key + 1" :key="`month-${month}`">{{ month }}</option>
                                </select>

                                <label for="birthMonth" >Month</label>
                            </div>

                            <div class="form-floating">
                                <select
                                    class="form-select new-input"
                                    name="birthDay"
                                    id="birthDay"
                                    v-model.trim="birthDay"
                                    :disabled="!birthMonth"
                                    @change="clearError('dateOfBirth')"
                                >
                                    <option :value="null" selected disabled>DD</option>
                                    <option v-for="day in getDays(birthYear, birthMonth)" :value="day" :key="`day-${day}`">{{ day }}</option>
                                </select>

                                <label for="birthDay">Day</label>
                            </div>
                        </div>
                    </div>

                    <template v-if="hasError('dateOfBirth')">
                        <error-message
                            v-for="message in getErrors('dateOfBirth')"
                            :key="message"
                            :message="message"
                            class="text-start"
                        ></error-message>
                    </template>
                </div>

                <div v-if="isTooYoung" class="col-12">
                    <div class="alert alert-info">
                        As per our admissions policy, based on the Date of Birth you have selected, you are currently too young to be admitted to our Adult Matric programme.
                        <br>
                        <a href="#" class="new-btn new-btn-dark new-btn-sm d-block switch-button mt-3" @click.prevent="switchToCaps">Switch to CAPS</a>
                    </div>
                </div>

                <div class="col-12">
                    <label class="form-label d-block text-start">ID or Passport number</label>

                    <div v-for="option in idTypeOptions" :key="option.value" class="form-check text-start">
                        <input
                            class="form-check-input"
                            type="radio"
                            :value="option.value"
                            name="identificationType"
                            v-model.trim="identificationType"
                            :id="option.value"
                            @change="clearError('identificationType')"
                        >

                        <label class="form-check-label" :for="option.value">
                            {{ option.text }}
                        </label>
                    </div>

                    <template v-if="hasError('identificationType')">
                        <error-message
                            v-for="message in getErrors('identificationType')"
                            :key="message"
                            :message="message"
                            class="text-start"
                        ></error-message>
                    </template>
                </div>

                <div class="col-12">
                    <div class="form-floating">
                        <input
                            type="text"
                            name="identificationNumber"
                            id="id_or_passport"
                            class="form-control new-input"
                            placeholder="Last Name"
                            v-model.trim="identificationNumber"
                            @keydown="clearError('identificationNumber')"
                        >
                        <label for="id_or_passport" class="form-label">{{ identificationType ?? 'ID or Passport number' }}</label>
                    </div>

                    <template v-if="hasError('identificationNumber')">
                        <error-message
                            v-for="message in getErrors('identificationNumber')"
                            :key="message"
                            :message="message"
                            class="text-start"
                        ></error-message>
                    </template>
                </div>
            </div>

            <div v-if="captureGuardianDetails" class="row">
                <div class="col-12 dialing-inputs">
                    <div class="dialing-inputs-code">
                        <div class="form-floating">
                            <select
                                class="form-select new-input"
                                id="dialCodeSelect"
                                name="dialingCode"
                                v-model.trim="dialingCode"
                                @change="clearError('dialingCode')"
                                :disabled="!captureGuardianDetails"
                            >
                                <option :value="null" selected disabled>Please select</option>
                                <option v-for="(option, key) in dialCodes" :value="key" :key="key">{{ option }}</option>
                            </select>

                            <label for="dialCodeSelect">Dialing Code</label>
                        </div>
                    </div>

                    <input v-if="!captureGuardianDetails" type="hidden" name="dialingCode" v-model.trim="dialingCode">

                    <div class="dialing-inputs-number">
                        <div class="form-floating">
                            <input
                                type="tel"
                                class="form-control new-input"
                                id="mobileNumberInput"
                                name="mobileNumber"
                                placeholder="Mobile Number"
                                v-model.trim="mobileNumber"
                                @keydown="clearError('mobileNumber')"
                                :readonly="!captureGuardianDetails"
                            />

                            <label for="mobileNumberInput">Mobile Number</label>
                        </div>
                    </div>

                    <template v-if="hasError('dialingCode')">
                        <error-message
                            v-for="message in getErrors('dialingCode')"
                            :key="message"
                            :message="message"
                            class="text-start"
                        ></error-message>
                    </template>

                    <template v-if="hasError('mobileNumber')">
                        <error-message
                            v-for="message in getErrors('mobileNumber')"
                            :key="message"
                            :message="message"
                            class="text-start"
                        ></error-message>
                    </template>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <h4 class="text-start text-dark">Please select your desired ASC exam sitting</h4>
                </div>

                <div v-for="(option, key) in amSittings" :key="option.id" class="col-6">
                    <vi-selectable-card
                        class="theme-dark pt-4"
                        :disabled="false"
                        :key="option.id"
                        :label="option.friendlyName"
                        :trueValue="option.id"
                        v-model="selectedAmSitting"
                        :isChecked="selectedAmSitting === option.id"
                        name="selectedAmSitting"
                        @input="clearError('selectedAmSitting')"
                        :tag="recommendedSitting === option.id ? 'Recommended' : null"
                    >
                    </vi-selectable-card>
                </div>

                <template v-if="hasError('selectedStartDateId')">
                    <error-message
                        v-for="message in getErrors('selectedStartDateId')"
                        :key="message"
                        :message="message"
                        class="text-start"
                    ></error-message>
                </template>

                <div class="col-12">
                    <p class="m-0">Choosing when you'd like to write your ASC exam helps us plan a weekly schedule for you, from the time you enrol until your exam date.</p>
                </div>
            </div>

            <div class="row" ref="startDateSection" v-if="selectedAmSitting">
                <div class="col-12">
                    <h4 class="text-start text-dark">Please select your start date</h4>
                </div>

                <div v-for="(option, key) in selectedSitting.startDates" :key="key" class="col-6">
                    <vi-selectable-card
                        class="theme-dark"
                        :disabled="false"
                        :key="key"
                        :label="option.value"
                        :trueValue="option.id"
                        v-model="selectedStartDateId"
                        :isChecked="selectedStartDateId === option.id"
                        name="selectedStartDateId"
                        @input="clearError('selectedStartDateId')"
                        :hasCountdown="true"
                        :tag="firstStartDate === option.id ? 'Recommended' : null"
                    >
                        <template v-if="option.description" v-slot:content>
                            <p class="text-center curriculum-description">{{ option.description }}</p>
                        </template>
                    </vi-selectable-card>
                    <countdown-timer v-if="selectedStartDateId === option.id" :countdown-date="option.enabled_to"></countdown-timer>
                </div>

                <template v-if="hasError('selectedStartDateId')">
                    <error-message
                        v-for="message in getErrors('selectedStartDateId')"
                        :key="message"
                        :message="message"
                        class="text-start"
                    ></error-message>
                </template>

                <div class="col-12">
                    <p class="m-0">Please note these start dates will be preparing you to write your ASC exams in {{ selectedSitting.friendlyName }}.</p>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <h4 class="text-start text-dark m-0">Need help choosing start date?</h4>
                </div>

                <div class="col-12">
                    <p class="text-start m-0">If you're planning to complete the full ASC with all six subjects, we recommend starting about 12 - 18 months before your exam date. You can join whenever you're ready, but starting earlier gives you more time to go through everything and feel confident for your exam.</p>
                </div>
            </div>

            <div v-if="errorMessage" class="row py-3">
                <div class="col-12">
                    <div class="alert alert-danger" v-html="errorMessage"></div>
                </div>
            </div>

            <div v-if="isTooYoung" class="row py-3">
                <div class="col-12">
                    <div class="alert alert-danger my-0">
                        As per our admissions policy, based on the Date of Birth you have selected, you are currently too young to be admitted to our Adult Matric programme. Please consider applying to our CAPS offering instead.
                    </div>
                </div>
            </div>

            <div :class="['row', { 'pt-5': !errorMessage }]">
                <div class="col-12 step-buttons">
                    <vi-button-spinner
                        buttonId="appStepOne"
                        :buttonClass="{
                            'new-btn': true,
                            'new-btn-dark': true,
                            'new-btn-sm': true,
                        }"
                        :disabled="submitDisabled"
                        :buttonText="nextButtonText"
                        :loading="loading"
                    ></vi-button-spinner>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import moment from 'moment';
import document_type_mixin from '../../mixins/document_type_mixin';
import { isEmpty } from 'lodash';
import scroll_to from '../../mixins/scroll_to';
import * as Sentry from "@sentry/vue";

export default {
    name: 'AdultMatricLearnerInfo',
    mixins: [
        document_type_mixin,
        scroll_to
    ],
    props: {
        applicationId: {
            type: String,
            required: false,
            default: null,
        },
        appliedCurriculum: {
            type: Number,
            required: true,
        },
        captureGuardianDetails: {
            type: Boolean,
            required: true,
        },
        user: {
            type: Object,
            required: true,
        },
        dialCodes: {
            type: Object,
            required: true,
        },
        backUrl: {
            type: String,
            required: true,
        },
        nextButtonText: {
            type: String,
            required: true,
        },
        submitUrl: {
            type: String,
            required: true,
        },
        guardianContact: {
            type: Object,
            required: true,
        },
        enrollmentRules: {
            type: Object,
            required: true,
        },
        application: {
            type: Object,
            required: false,
            default: null,
        },
        amReenrolmentId: {
            type: String,
            required: false,
            default: null,
        },
        offeredCurriculumOptions: {
            type: Array,
            required: true,
        },
        amSittings: {
            type: Array,
            required: true,
        },
        selectedExamSittingInfo: {
            type: Object,
            required: false,
        },
    },
    data() {
        return {
            firstName: null,
            lastName: null,
            birthYear: null,
            birthMonth: null,
            birthDay: null,
            identificationType: null, // 'Passport' or 'ID'
            identificationNumber: null, // ID or pass port number based on what's set for identificationType
            selectedStartDateId: null,
            dialingCode: null,
            mobileNumber: null,
            selectedAmSitting: null,
            firstStartDate: null,
            recommendedSitting: null,

            // Component State
            loading: false,
            idTypeOptions: null,
            errors: null,
            errorMessage: null,
            submitDisabled: false,
            startDateExpired: false,

            months:  [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
            ],
        };
    },
    mounted() {
        this.firstName = this.user.first_name;
        this.lastName = this.user.last_name === 'unknown' ? null : this.user.last_name;
        this.dialingCode = this.user.mobile_number_country;
        this.mobileNumber = this.user.mobile_number_national;

        let totalSittings = this.amSittings.length;
        this.recommendedSitting = this.amSittings[totalSittings - 1].id;

        if (this.selectedExamSittingInfo && this.selectedExamSittingInfo.sittingID && this.selectedExamSittingInfo.startDateId) {
            this.selectedAmSitting = this.selectedExamSittingInfo.sittingID;

            this.$nextTick(() => {
                this.selectedStartDateId = this.selectedExamSittingInfo.startDateId;
            });
        } else {
            this.selectedAmSitting = this.recommendedSitting;
        }

        if (this.guardianContact) {
            this.identificationType = this.guardianContact.VMS_ID_Type__c;
            this.$nextTick(() => {
                this.identificationNumber = this.guardianContact.ID_or_Passport_Number__c;
            });
        }

        if (this.user.date_of_birth) {
            let dobParts = /^(?<year>\d{4})\-(?<month>\d{2})\-(?<day>\d{2})$/.exec(this.user.date_of_birth);

            // Has valid date of birth with matched groups.
            if (dobParts.groups && Object.keys(dobParts.groups).length > 0) {
                let dobDetails = dobParts.groups;

                this.birthYear = parseInt(dobDetails.year);
                this.birthMonth = parseInt(dobDetails.month);
                this.$nextTick(() => { // Allow days to be calculated.
                    this.birthDay = parseInt(dobDetails.day);
                });
            }
        }

        this.idTypeOptions = this.getUploadDocumentTypeOptions(
            this.UploadDocumentTypes.BirthCertificate,
        );
        this.idTypeOptions.reverse();

        // check if start_date_expired is set to true in url
        const urlParams = new URLSearchParams(window.location.search);
        this.startDateExpired = urlParams.get('start_date_expired') === 'true';

        if (urlParams.has('changeStartDate')) {
            this.scrollIntoView(this.$refs.startDateSection);
        }
    },
    computed: {
        selectedSitting() {
            return this.amSittings.find(sitting => sitting.id === this.selectedAmSitting);
        },
        singleSittingAvailable() {
            return this.amSittings.length === 1;
        },
        firstError() {
            if (!Object.keys(this.errors).length) {
                return null;
            }

            return document.querySelectorAll('.invalid-feedback')[0];
        },
        isTooYoung() {
            if (this.selectedAmSitting === null) {
                return false;
            }

            let isTooYoung = true;

            let startYears = Object.values(this.amSittings).map(sitting => sitting.date).map(date => date.split('-')[0]);

            for (const year of startYears) {
                isTooYoung = isTooYoung && this.isTooYoungForYear(year);
            }

            return isTooYoung;
        },
    },
    methods: {
        getBirthYears() {
            let pastHundreadyears = (new Date()).getFullYear() - 10;
            let years = Array.from({ length : 100 }, (v , k) => pastHundreadyears - k);

            return years;
        },
        getDays(year, month) {
            return new Date(year, month, 0).getDate();
        },
        submit(event) {
            let data = new FormData(event.target);

            data.append('appliedCurriculum', this.appliedCurriculum);
            data.append('dateOfBirth', `${this.birthYear}-${this.birthMonth}-${this.birthDay}`);
            data.append('amReenrolmentId', this.amReenrolmentId);
            data.append('amSittingId', this.selectedAmSitting);


            if (!isEmpty(this.applicationId)) {
                data.append('applicationId', this.applicationId);
            }

            this.loading = true;
            this.errorMessage = null;
            this.errors = null;

            axios.post(this.submitUrl, data)
                .then(response => {
                    this.triggerGaEvent();

                    if (response.data.hasOwnProperty('redirect') && response.data.redirect) {
                        window.location = response.data.redirect;
                        this.submitDisabled = true;
                    }
                })
                .catch(error => {
                    let data = error.response.data;

                    // If error has no 'response' attached or if there's a response but no data, log.
                    if (!error.hasOwnProperty('response') || !error.response.hasOwnProperty('data')) {
                        Sentry.captureException(error);

                        // Show a generic error message.
                        this.errorMessage = 'We failed to submit your application. Please check the provided details and try again. If the problem persists please contact support.';
                        return;
                    }

                    let hasErrors = data.hasOwnProperty('errors') && data.errors;
                    let hasError = data.hasOwnProperty('error') && data.error;

                    if (data.hasOwnProperty('redirect') && data.redirect) {
                        window.location = data.redirect;
                        this.submitDisabled = true;
                        return;
                    }

                    if (hasErrors) {
                        this.errors = data.errors;
                        this.errorMessage = 'Please correct errors before continuing';
                        this.submitDisabled = true;

                        this.$nextTick(this.scrollToFirstError);
                    }

                    if (hasError) {
                        this.errorMessage = data.error;
                    }

                    // Default error message if non is provided by the API response.
                    if (!hasError && !hasErrors) {
                        this.errorMessage = 'We failed to submit your application. Please check the provided details and try again. If the problem persists please contact support.';
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        hasError(property) {
            if (!this.errors) {
                return false;
            }

            return this.errors.hasOwnProperty(property) && this.errors[property].length > 0;
        },
        getErrors(property) {
            if (!this.hasError(property)) {
                return null;
            }

            return this.errors[property];
        },
        clearError(property) {
            if (this.errors && this.errors.hasOwnProperty(property)) {
                delete this.errors[property];

                if (Object.keys(this.errors).length <= 0) {
                    this.errors = null;
                }
            }
        },
        scrollToFirstError() {
            if (!this.firstError) {
                return;
            }

            this.firstError.scrollIntoView({
                behavior: 'auto',
                block: 'center',
                inline: 'center',
            });
        },
        triggerGaEvent() {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'learnerInformation',
                'learnerRelationship': 'Myself',
                'gradeApplying': 'Adult Matric',
                'curriculum': 'Adult Matric',
                'userid': window.valenture.userId,
            });
        },
        isTooYoungForYear(year) {
            if (!(this.birthYear && this.birthMonth && this.birthDay)) {
                return false;
            }

            if (!this.enrollmentRules) {
                return false;
            }

            let dobDate = moment(new Date(this.birthYear, this.birthMonth - 1, this.birthDay));
            let minAgeDate = new Date(`${year}-${this.enrollmentRules.min_age_day}`);

            dobDate.set({hours: 0, minutes: 0, seconds: 0});
            minAgeDate.setHours(0, 0, 0, 0);

            let ageAtMinDate = this.calculateAge(minAgeDate);

            return ageAtMinDate < this.enrollmentRules.min_age;
        },
        calculateAge(onDate) {
            let dateOfBirth = new Date(this.birthYear, this.birthMonth - 1, this.birthDay);
            let targetDate = new Date(onDate);
            let age = targetDate.getFullYear() - dateOfBirth.getFullYear();
            let m = targetDate.getMonth() - dateOfBirth.getMonth();

            if (m < 0 || (m === 0 && targetDate.getDate() < dateOfBirth.getDate())) {
                age--;
            }

            return age;
        },
        switchToCaps() {
            let caps = this.offeredCurriculumOptions.find(curriculum => curriculum.identifier === 'caps');

            this.$emit('curriculum-select', { chosenCurriculum: caps.id });
        },
    },
    watch: {
        birthMonth() {
            this.birthDay = null;
        },
        identificationType() {
            this.identificationNumber = null;
        },
        errors(val) {
            // Clear error message once all errors have been cleared.
            if (!val) {
                this.errorMessage = null;
                this.submitDisabled = false; // Re-enable submit
            }
        },
        selectedAmSitting() {
            this.selectedStartDateId = null;
            if (this.selectedAmSitting) {
                this.firstStartDate = Object.values(this.selectedSitting.startDates)[0].id;

                if (!this.selectedStartDateId) {
                    this.selectedStartDateId = this.firstStartDate;
                }
            }
        },
    }
}
</script>

<style lang="scss" scoped>
@import '../../../sass/abstract/colours.scss';

label {
    color: $pastel-blue;
}

.adult-matric-learner-info {
    max-width: calc(600px + 3rem);
    margin: 0 auto;
    padding: 1rem 1.5rem;
}

li {
    text-align: left;
}

.dialing-inputs {
    display: grid;
    grid-template-areas: "code number";
    grid-template-columns: calc(100% * (1/3)) calc(100% * (2/3) - 12px);
    column-gap: 12px;

    &-code {
        grid-area: code;
    }

    &-number {
        grid-area: number;
    }

    .invalid-feedback {
        grid-column-start: 1;
        grid-column-end: 3;
    }
}

.step-buttons {
    display: flex;
    justify-content: flex-end;

    .new-btn:hover {
        cursor: pointer;
    }
}

input[readonly] {
    pointer-events: none;

    &:focus-visible {
        background-color: #e9ecef;
    }

    &:hover {
        cursor: not-allowed;
    }
}

.switch-button {
    max-width: none;
}
</style>
